export const environment = {
    production: false,
    "BUYER_EMAIL": "support@moglix.com",
    "BUYER_NUMBER": "+91 955 5988 544",
    microsoft: {
        CLIENT_ID: '8afa6760-4915-4a03-b572-1c2fa5438200',
        TENANT_ID: '421e9584-87ff-424f-95b8-1bf46b70db99'
    },
    sentry: {
        DSN: 'https://8dab80f98d3349f6ae372687332996d4@sentry.moglix.com/16',
        ORIGIN : 'https://buyersqanew.moglix.com'
    },
    URLS: {
        "WS_URL": "wss://procurementqa.moglix.com/procurement/",
        "AUTH_URL": "https://authb2b.moglix.com/login/",
        "PROCUREMENT_URL": "https://procurementqa.moglix.com/procurement/",
        "DATASYNC_URL": " https://datasyncqa.moglilabs.com/buyers/",
        "MOGLIX_API_URL": "https://apiqa.moglilabs.com/",
        "DOMAIN": "buyersqa.moglix.com",
        "DEPLOYMENT_MODE": "test",
        "IDENTITY": "BUYERS",
        "INVOICE_URL": "https://invoice.moglilabs.com",
        "EMS_API": "https://emsqa.moglilabs.com/",
        "EMS_API2" : "https://lms.moglix.com/",
        "EMS_API2_ACCESS_KEY" : "5864f77b-03b3-4b24-ba1c-683f2e6082fb",
        "EMS_WMS" : "https://wms.moglilabs.com/",
        "EMS_WMS_TOKEN" : "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2YWliaGF2LnRoYXBsaXlhbEBtb2dsaXguY29tIiwiZW1haWwiOiJ2YWliaGF2LnRoYXBsaXlhbEBtb2dsaXguY29tIiwidXNlcktleSI6ImVIZ1FxVWl5eUpuQWZsTXNCa2pQRGJMTWJDTHRpWGR1SHZXelNhYVl0TW5kZnRWQ01UbEZwTnBDRm1qcWFCSkUifQ.ET97qIOLi8SABABK5zl3dQbKZMxVwf8Q05wJhBJ8Na4",
        "BUCKET_URL": "stg.moglix.com/",
        "GST_SERVER_URL": "https://gstkb.greengst.com",
        "key": "451964377672-h1vj251ckbdni1cdak9is7negqcl627k.apps.googleusercontent.com",
        "GSTNDETAILS_URL": "https://newproductionapi.greengst.com/public_api/getTaxpayerByGstin",
        "SAP_URL": "https://sapqa.moglilabs.com/",
        "SAP_ABFRL_URL":"https://sap-abfrlqa.moglilabs.com/",
        "UPDATE_PO": "http://emsqa.moglilabs.com/",
        "EOC_API": "https://buyerintqa.moglilabs.com/",
        "BUYER_INT_API2": 'https://buyersintapiqa2.moglix.com/',
        'DASHBOARD_API': 'https://analysis.moglix.com/',
        'SALES_API': 'https://salesopsapi-qa1.moglilabs.com/',
        'SALES_API_TOKEN': 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJpbnRlZ3JhdGlvbi5ib3RAbW9nbGl4LmNvbSIsImVtYWlsIjoiaW50ZWdyYXRpb24uYm90QG1vZ2xpeC5jb20iLCJ1c2VyS2V5IjoiRVRNaVRvUldpSldCSXpxWm9rcWxMc09kRXNqaUpOYk1icUpSREl6QnRhT1dkU296Um9OZkVERkdZT2dEQkpGWCJ9.UJALC_hElxM0muDHlLZu26NZYMBs17y7tkz3h2vGfoo',
        'CENTRAL_API': 'https://mce-account-service-qa.moglilabs.com/account/',
        'CENTRAL_APITOKEN': 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJidXllcmludC1idXllcnNAb21nbGl4LmNvbSIsImlkUmVmcmVzaFRva2VuIjoxNjc5NSwiZXhwIjoxNzY0MTE3MDAwLCJpYXQiOjE3MzI2MjE4NTYsImVtYWlsIjoiYnV5ZXJpbnQtYnV5ZXJzQG9tZ2xpeC5jb20ifQ.8q567Kun2G4ws3J4h4SJ-f6seruzWwSBkuk5QaN_1QhD0G20Sl7Bw1SeCajWwto1sPG4X5EecfDLbLqze9AALA',
        "SEARCH_URL": "https://buyer-search-qa.moglilabs.com/",
        'CLIENT_URL': "https://maas-core-qa.moglilabs.com/",
        "CUSTOMER_URL": "https://maas-core-qa.moglilabs.com/customer/",
        'CATALOG_URL': 'https://nodeapiqa.moglilabs.com/nodeApi/v1/',
        'SEARCH_ML_URL': 'https://searchml.moglix.com/',
        'REPORT_SERVICE_URL': 'https://report-service-qa.moglilabs.com/',
        'CONSUMER_POOL_API' : 'https://buyerconsumer.moglilabs.com/',
        'PURCHASE_URL':'https://purchase.moglilabs.com/',
        'PURCHASE_API_TOKEN' : 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzYXAtcHJvZHVjZXJAb21nbGl4LmNvbSIsImlkUmVmcmVzaFRva2VuIjoxNjMyNSwiZXhwIjoxNzU5NDUxNDAwLCJpYXQiOjE3Mjc5MzIxNjQsImVtYWlsIjoic2FwLXByb2R1Y2VyQG9tZ2xpeC5jb20ifQ._CMRpP1cG4FBPLT_TTqr7oQk3jav5qfvLyX42zLxt4SonJYYp2SIqNcmjSB7DbteqI7bDi5BIL39oSLhsg-roQ',
        "FINANCER_API":"https://financear.moglilabs.com/",
        'BUYERS_UI': 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJidXllcnMtdWlAb21nbGl4LmNvbSIsImlkUmVmcmVzaFRva2VuIjoxNzU5NCwiZXhwIjoxNzQxNjMxNDAwLCJpYXQiOjE3NDEwNzI0NzAsImVtYWlsIjoiYnV5ZXJzLXVpQG9tZ2xpeC5jb20ifQ.mkdhWa1esRrgvcpfPawWcPGszPuzkHKUUylx4r3dbNz6ynRb9Q4BZJcgVKJahG8SVo-tLuc8uKkD-KZuwVG9ng'
    },
    GTM_TAG: 'GTM-K6483BR'
};