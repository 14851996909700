import { Injectable } from '@angular/core';
import { DataService } from './../data.service';
import { environment } from '../../../environments/environment';
import { CommonapiService } from '../commonapi.service';
import { METHODS } from 'http';

export const URLS_INFO = {
    CATEGORY_BRANDS: { METHOD: 'POST', URL: environment.URLS.EOC_API + 'product/categories' },
    PRODUCTS: { METHOD: 'POST', URL: environment.URLS.EOC_API + 'product/es-search' },
    CIS:{METHOD:'POST',URL:environment.URLS.EOC_API +'cis/es-search'},
    UPDATE_CART: { METHOD: 'POST', URL: environment.URLS.PROCUREMENT_URL + 'cart/upsert/po' },
    FETCH_CART_DETAILS: { METHOD: 'GET', URL: environment.URLS.EOC_API + 'cart/get/po' },
    FETCH_SUPPLIERS: { METHOD: 'POST', URL: environment.URLS.EOC_API + 'msn-supplier' },
    CREATE_RFQ: { METHOD: 'POST', URL: environment.URLS.EOC_API + 'po/rfqitem/create' },
    COMPNAY_DTEAILS: { METHOD: 'POST', URL: environment.URLS.AUTH_URL + 'company/get' },
    FETCH_CATALOGUE_CONFIG: { METHOD: 'POST', URL: environment.URLS.EOC_API + 'configuration/header/config/get' },
    // FETCH_BRANCH_ACCESS: { METHOD: 'POST', URL: environment.URLS.AUTH_URL + 'user/getBranchAccess' },
    FETCH_PRICE_RANGES: { METHOD: 'POST', URL: environment.URLS.EOC_API + 'product/price-filter' },
    DOWNLOAD_CSV: { METHOD: 'POST', URL: environment.URLS.EOC_API + 'product/es-search/download'},
    GET_ALL_CATEGORIES: { METHOD: 'GET', URL: environment.URLS.EOC_API + 'gettingCategoryListAndImages'},
    GET_LAST_PURCHASED_ITEMS:{ METHOD: 'GET', URL: environment.URLS.EOC_API + 'lastPurchaseItems'},
    GET_RECENTLY_VIEWED_ITEMS: {METHOD: 'POST', URL: environment.URLS.EOC_API + 'getRecentlyViewedItems'},
    GET_BRAND_DETAILS: { METHOD: 'GET', URL: environment.URLS.EOC_API + 'shopByBrands'},
    PRODUCTS_BY_CATEGORY_CODES: {METHOD: 'POST', URL: environment.URLS.EOC_API + 'productsByCategoryCodes'},
    GET_LOA_QUANTITY: {METHOD: 'POST', URL: environment.URLS.EOC_API +'po/loaQuantity/getQty'}
}

@Injectable()
export class CatalogueService
{

    constructor(private dataService: DataService, private _commonapiService: CommonapiService) { }

    getCategoryBrands(request)
    {
        return this.dataService.callRestful(URLS_INFO.CATEGORY_BRANDS.METHOD, URLS_INFO.CATEGORY_BRANDS.URL, request);
    }

    getProducts(request, pageNumber,isCis = false)
    {
        if(isCis){
            return this.dataService.callRestful(URLS_INFO.CIS.METHOD, URLS_INFO.CIS.URL +'?page=' + pageNumber + '&size=40', request);
        }
        return this.dataService.callRestful(URLS_INFO.PRODUCTS.METHOD, URLS_INFO.PRODUCTS.URL + '?page=' + pageNumber + '&size=40', request);
    }

    downloadProductsCsv(request, pageNumber) {
        return this.dataService.callRestful(URLS_INFO.DOWNLOAD_CSV.METHOD, URLS_INFO.DOWNLOAD_CSV.URL + '?page=' + pageNumber + '&size=40', request);
    }

    getCartDetails()
    {
        return this.dataService.callRestful(URLS_INFO.FETCH_CART_DETAILS.METHOD, URLS_INFO.FETCH_CART_DETAILS.URL);
    }

    updateCartDetails(request)
    {
        return this.dataService.callRestful(URLS_INFO.UPDATE_CART.METHOD, URLS_INFO.UPDATE_CART.URL, request);
    }

    getSuppliers(request)
    {
        return this.dataService.callRestful(URLS_INFO.FETCH_SUPPLIERS.METHOD, URLS_INFO.FETCH_SUPPLIERS.URL, request);
    }

    createRFQ(request)
    {
        return this.dataService.callRestful(URLS_INFO.CREATE_RFQ.METHOD, URLS_INFO.CREATE_RFQ.URL, request);
    }

    getCompanyDetails(request)
    {
        return this.dataService.callRestful(URLS_INFO.COMPNAY_DTEAILS.METHOD, URLS_INFO.COMPNAY_DTEAILS.URL, request);
    }

    getPageConfiguration(request)
    {
        return this.dataService.callRestful(URLS_INFO.FETCH_CATALOGUE_CONFIG.METHOD, URLS_INFO.FETCH_CATALOGUE_CONFIG.URL, request);
    }

    getBranchAccess(request) 
    {
        return this.dataService.callRestful(this._commonapiService.getUrls().FETCH_BRANCH_ACCESS.METHOD, this._commonapiService.getUrls().FETCH_BRANCH_ACCESS.URL, request);
    }

    getPriceRanges(request) {
        return this.dataService.callRestful(URLS_INFO.FETCH_PRICE_RANGES.METHOD, URLS_INFO.FETCH_PRICE_RANGES.URL, request);
    }

    getAllCategories(){
        return this.dataService.callRestful(URLS_INFO.GET_ALL_CATEGORIES.METHOD, URLS_INFO.GET_ALL_CATEGORIES.URL);
    }

    getLastPurchasedItems(oneMonth: boolean) {
        const url = `${URLS_INFO.GET_LAST_PURCHASED_ITEMS.URL}?oneMonth=${oneMonth}`;
        return this.dataService.callRestful(
            URLS_INFO.GET_LAST_PURCHASED_ITEMS.METHOD, 
            url
        );
    }
    
    getRecentlyViewedItems(request){
        return this.dataService.callRestful(URLS_INFO.GET_RECENTLY_VIEWED_ITEMS.METHOD, URLS_INFO.GET_RECENTLY_VIEWED_ITEMS.URL ,request);
    }

    getBrandDetails(){
        return this.dataService.callRestful(URLS_INFO.GET_BRAND_DETAILS.METHOD , URLS_INFO.GET_BRAND_DETAILS.URL)
    }

    getProductsFromCategoryCodes(requestBody) {
        return this.dataService.callRestful(URLS_INFO.PRODUCTS_BY_CATEGORY_CODES.METHOD, URLS_INFO.PRODUCTS_BY_CATEGORY_CODES.URL, requestBody)
    }

    getLoaQuantity(request){
        return this.dataService.callRestful(URLS_INFO.GET_LOA_QUANTITY.METHOD, URLS_INFO.GET_LOA_QUANTITY.URL, request);
    }
}
